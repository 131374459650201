.checkout-form .card-frame,
.card-number-frame,
.expiry-date-frame,
.cvv-frame {
  height: 38px;
}

.checkout-form #schemeChoice {
  height: 200px !important;
}

.checkout-form #payment-form {
  width: 280px;
  margin: 0 auto;
}

.checkout-form label {
  display: block;
  height: 10px;
  color: #13395e;
  font-size: 14px;
  font-weight: 500;
  line-height: 10px;
  margin: 8px 0;
}

.checkout-form .date-and-code {
  display: flex;
  margin-bottom: 8px;
}

.checkout-form .date-and-code > div:nth-child(1) {
  width: 55.715%;
}

.checkout-form .date-and-code > div:nth-child(2) {
  width: 45.719%;
}

.checkout-form .input-container {
  position: relative;
  display: flex;
  height: 40px;
}

.checkout-form .icon-container:last-child {
  right: 0;
}

.checkout-form .icon-container.payment-method {
  right: 0;
}

.checkout-form .input-container.card-number {
  margin-bottom: 8px;
}

.checkout-form .input-container.expiry-date {
  margin-right: 4px;
}

.checkout-form .input-container.cvv {
  margin-left: 4px;
}

.checkout-form .card-number-frame {
  padding-left: 40px;
}

.checkout-form .expiry-date-frame {
  padding-left: 40px;
}

.checkout-form .cvv-frame {
  padding-left: 40px;
}

.checkout-form div + button {
  margin-top: 8px;
}

.checkout-form .icon-container {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 26px;
  margin: 0 7px;
}

.checkout-form .icon-container.payment-method {
  transform: translateY(-50%) rotateY(90deg);
  transition: opacity 0.15s ease-out;
  opacity: 0;
  top: 50%;
}

.checkout-form .icon-container.payment-method.show {
  opacity: 1;
  transition: all 0.4s ease-out;
  transform: translateY(-50%) rotateY(0deg);
}

.checkout-form .icon-container.payment-method img {
  width: 100%;
}

.checkout-form [id$='-error'] {
  display: none;
}

.checkout-form .frame {
  opacity: 0;
}

.checkout-form .frame--activated {
  opacity: 1;
  border: solid 1px #13395e;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(19, 57, 94, 0.2);
}

.checkout-form .frame--activated.frame--focus {
  border: solid 1px #13395e;
  box-shadow: 0 2px 5px 0 rgba(19, 57, 94, 0.15);
}

.checkout-form .frame--activated.frame--invalid {
  border: solid 1px #d96830;
  box-shadow: 0 2px 5px 0 rgba(217, 104, 48, 0.15);
}

.checkout-form .error-message {
  display: block;
  color: #c9501c;
  font-size: 0.9rem;
  margin: 8px 0 0 1px;
  font-weight: 300;
}

.checkout-form #pay-button {
  /* margin-top: 150px; */
  border: none;
  border-radius: 3px;
  color: #fff;
  font-weight: 500;
  height: 40px;
  /* width: 100%; */
  background-color: #13395e;
  box-shadow: 0 1px 3px 0 rgba(19, 57, 94, 0.4);
}

.checkout-form #pay-button:active {
  background-color: #0b2a49;
  box-shadow: 0 1px 3px 0 rgba(19, 57, 94, 0.4);
}

.checkout-form #pay-button:hover {
  background-color: #15406b;
  box-shadow: 0 2px 5px 0 rgba(19, 57, 94, 0.4);
}

.checkout-form #pay-button:disabled {
  background-color: #697887;
  box-shadow: none;
}

.checkout-form #pay-button:not(:disabled) {
  cursor: pointer;
}

.checkout-form .success-payment-message {
  color: #13395e;
  line-height: 1.4;
}

.checkout-form .token {
  color: #b35e14;
  font-size: 0.9rem;
  font-family: monospace;
}

/**
IE11-targeted optimisations
 */

 .checkout-form _:-ms-fullscreen,
:root .icon-container {
  display: block;
}

.checkout-form _:-ms-fullscreen,
:root .icon-container img {
  top: 50%;
  -ms-transform: translateY(-50%);
  position: absolute;
}

.checkout-form _:-ms-fullscreen,
#icon-card-number,
_:-ms-fullscreen,
#icon-expiry-date,
_:-ms-fullscreen,
#icon-cvv {
  left: 7px;
}

.checkout-form #checkout-frames-card-number::-ms-clear {
  display: none;
}
